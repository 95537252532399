import React, { PureComponent } from "react"
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll'
import { navigate  } from "gatsby"
import './Topbar.scss'
import Logo from '../../assets/images/logo.png'
import locales from '../../constants/locales'

class Topbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    onClick = (item) => {
        this.setState({open: false})
        document.body.style = '';
        scroller.scrollTo(item, {
            duration: 800,
            smooth: true
        })
    }

    render() {
        const { scrollPosition, voices, locale, path, languageUrl, children } = this.props;
        const { open } = this.state;
        const { MENU } = locales[locale].textEntries;
        return (
            <header className={ "HeaderContainer" + ((scrollPosition > 0) ? " scrolled" : "") + ((open) ? " open" : "") }>
                <div className="LogoContainer">
                    <img src={Logo} alt="Nikolas Mosca Fullstack Web Developer" onClick={() => {
                        if(document.body.clientWidth > 1000) return
                        document.body.style = (!open) ? 'overflow-y:hidden' : '';
                        this.setState({open: !open})}
                    }/>
                    <div className="SuggestionClick">{ MENU }</div>
                </div>
                <nav className="MenuContainer">
                    <ul>
                        {children}

                        { 
                            voices.map(item => 
                                <li key={item} onClick={() => this.onClick(item)}>{item}</li>
                            ) 
                        }

                        <li>
                            {
                                Object.keys(locales).map(item => 
                                    <span 
                                        key={item} 
                                        className={item + ((item === locale) ? ' active' : '')}
                                        onClick={() => {
                                            if(item === locale) return;

                                            let navigatePath = (languageUrl) ? languageUrl : '/'
                                            if(path) {
                                                navigatePath = navigatePath.split('/')
                                                if(navigatePath.includes('it') || navigatePath.includes('en')) {
                                                    navigatePath[1] = item 
                                                } 
                                                navigatePath = navigatePath.join('/')
                                            } else {
                                                if(!locales[item].default) {
                                                    navigatePath += item
                                                }
                                            }
                                            navigate(navigatePath)

                                            this.setState({open: false})
                                            document.body.style = '';
                                        }}
                                    >
                                        {item}
                                    </span>
                                )
                            }
                        </li>
                    </ul>
                </nav>
            </header>
        )
    }
}

Topbar.propTypes = {
    scrollPosition: PropTypes.number,
    voices: PropTypes.array,
    locale: PropTypes.string,
    path: PropTypes.string
}

export default Topbar