import React, { PureComponent } from "react"
import './BoxRenderer.scss'

class BoxRenderer extends PureComponent {
    render() {
        const { children, className } = this.props;
        return (
            <section className={"BoxRenderer " + ((className) ? className : '')}>
               {children} 
            </section>
        )
    }
}
export default BoxRenderer